.botContainer {
    min-height: 50vh;
}

.leftContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.imgContainer {
    background-color: rgba(0, 8, 219, 0.1);
    width: 68px;
    height: 68px;
    border-radius: 2000px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
.img {
    width: 44px;
    height: 44px;
}

.h1 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;

    color: #0008db;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.rightContainer ul {
    list-style-type: disc;
}

.rightContainer li {
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #515151;
}
@media (min-width: 400px) {
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 475px) {
}

@media (min-width: 576px) {
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .rightContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }

    .listLeft {
        margin-right: 24px;
    }
    .listRight {
        margin-left: 24px;
    }
}

@media (min-width: 840px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .listLeft {
        margin-right: 24px;
    }
    .listRight {
        margin-left: 24px;
    }
    .rightContainer li {
        font-family: Roboto;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        color: #515151;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .botContainer {
        min-height: 50vh;
    }

    .leftContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .rightContainer {
        margin-top: 64px;
    }

    .imgContainer {
        background-color: rgba(0, 8, 219, 0.1);
        width: 68px;
        height: 68px;
        border-radius: 2000px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 18px;
    }

    .h1 {
        font-family: Poppins;
        font-weight: bold;
        font-size: 57px;
        line-height: 64px;
        text-align: center;

        color: #0008db;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
@media (min-width: 1600px) {
}

@media (min-width: 1900px) {
    .listRight {
        margin-left: 64px;
    }
    .listLeft {
        margin-right: 64px;
    }
}

@media (min-width: 2560px) {
}
