.midContainer {
    min-height: 50vh;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 100px;
}

.headlineLarge {
    font-family: Poppins;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    color: #0008db;
}
.listItemContainer {
    margin-top: 28px;
}

@media (min-width: 400px) {
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 475px) {
}

@media (min-width: 576px) {
    .midContainer {
        padding-left: 32px;
        padding-right: 32px;
    }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .midContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 840px) {
    .midContainer {
        padding-left: 64px;
        padding-right: 64px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .listItemContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .midContainer {
        padding-left: 145px;
        padding-right: 145px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
@media (min-width: 1600px) {
    .midContainer {
        padding-left: 165px;
        padding-right: 165px;
    }
}

@media (min-width: 1900px) {
    .midContainer {
        padding-left: 212px;
        padding-right: 212px;
    }
    .listItemContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media (min-width: 2560px) {
}
