.midBotContainer {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.midInnerContainer {
    width: 242px;
    height: 242px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.c1 {
    width: 115px;
    height: 115px;

    background-color: #0008db;
    border: solid 3px #fbfdf7;
    border-radius: 20px;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;

    align-items: center;
}

.c2 {
    width: 115px;
    height: 115px;

    background-color: #0e8dff;

    border: solid 3px #fbfdf7;
    border-radius: 20px;

    margin-left: -20px;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;

    align-items: center;
}
.c3 {
    width: 115px;
    height: 115px;

    background-color: #0e8dff;
    border: solid 3px #fbfdf7;
    border-radius: 20px;
    margin-top: -20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;

    align-items: center;
}
.c4 {
    width: 115px;
    height: 115px;

    background-color: #0008db;
    border: solid 3px #fbfdf7;
    border-radius: 20px;
    margin-left: -20px;
    margin-top: -20px;
    display: flex;

    align-items: center;
}

.midInnerContainer div p {
    font-family: Poppins;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    width: 1px;
    margin-left: 12px;
    color: #fbfdf7;
}

@media (min-width: 400px) {
    .midInnerContainer {
        width: 350px;
        height: 350px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .c1 {
        width: 165px;
        height: 165px;

        background-color: #0008db;
        border: solid 5px #fbfdf7;
        border-radius: 20px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .c2 {
        width: 165px;
        height: 165px;

        background-color: #0e8dff;

        border: solid 5px #fbfdf7;
        border-radius: 20px;

        margin-left: -24px;
    }
    .c3 {
        width: 165px;
        height: 165px;

        background-color: #0e8dff;
        border: solid 5px #fbfdf7;
        border-radius: 20px;
        margin-top: -24px;
    }
    .c4 {
        width: 165px;
        height: 165px;

        background-color: #0008db;
        border: solid 5px #fbfdf7;
        border-radius: 20px;
        margin-left: -24px;
        margin-top: -24px;
    }
    .midInnerContainer div p {
        font-family: Poppins;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        width: 1px;
        margin-left: 12px;
    }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 475px) {
}

@media (min-width: 576px) {
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

@media (min-width: 840px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .midInnerContainer {
        width: 950px;
        height: 105px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .c1 {
        width: 284px;
        height: 89px;

        background-color: #0008db;
        border: solid 8px #fbfdf7;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-radius: 20px;
    }

    .c2 {
        width: 284px;
        height: 89px;

        background-color: #0e8dff;

        border: solid 8px #fbfdf7;

        border-radius: 20px;
        margin-left: -89px;
    }
    .c3 {
        width: 284px;
        height: 89px;
        background-color: #0e8dff;
        border: solid 8px #fbfdf7;

        border-radius: 20px;
        margin-top: -0;
        margin-left: -89px;
    }
    .c4 {
        width: 284px;
        height: 89px;

        background-color: #0008db;
        border: solid 8px #fbfdf7;

        border-radius: 20px;
        margin-left: -89px;

        margin-top: -0;
    }
    .midInnerContainer div p {
        font-family: Poppins;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        width: 1px;
        margin-left: 16px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1440px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
@media (min-width: 1600px) {
    .midInnerContainer {
        width: 1440px;
        height: 155px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .c1 {
        width: 439px;
        height: 139px;

        background-color: #0008db;
        border: solid 8px #fbfdf7;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-radius: 20px;
    }

    .c2 {
        width: 439px;
        height: 139px;

        background-color: #0e8dff;

        border: solid 8px #fbfdf7;

        border-radius: 20px;
        margin-left: -127px;
    }
    .c3 {
        width: 439px;
        height: 139px;
        background-color: #0e8dff;
        border: solid 8px #fbfdf7;

        border-radius: 20px;
        margin-top: -0;
        margin-left: -127px;
    }
    .c4 {
        width: 439px;
        height: 139px;

        background-color: #0008db;
        border: solid 8px #fbfdf7;

        border-radius: 20px;
        margin-left: -127px;

        margin-top: -0;
    }
    .midInnerContainer div p {
        font-family: Poppins;
        font-weight: bold;
        font-size: 36px;
        text-align: center;
        line-height: 45px;
        width: 1px;
        margin-left: 32px;
    }
}

@media (min-width: 1900px) {
}

@media (min-width: 2560px) {
}
