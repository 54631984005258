.listItem {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 16px;
    padding-bottom: 26px;
    margin-top: 10px;
    margin-bottom: 10px;

    border: solid 4px #e7f2e8;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imageContainer {
    width: 66px;
    height: 66px;
    border-radius: 1000px;
    background-color: rgba(0, 8, 219, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.img {
    width: 40px;
    height: 40px;
}

.headerText {
    margin-top: 12px;
    font-family: Poppins;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    text-align: center;

    color: #0008db;
}

.text {
    margin-top: 12px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #515151;
}

@media (min-width: 400px) {
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 475px) {
}

@media (min-width: 576px) {
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .listItem {
        width: 256px;
        height: 292px;
        padding: 0;
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 66px;
        padding-bottom: 40px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 12px;

        border: solid 4px #e7f2e8;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .imageContainer {
        width: 104px;
        height: 104px;
        border-radius: 1000px;
    }
    .img {
        width: 64px;
        height: 64px;
    }

    .headerText {
        margin-top: 32px;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        width: 200px;
    }

    .text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
}

@media (min-width: 840px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
@media (min-width: 1600px) {
}

@media (min-width: 1900px) {
}

@media (min-width: 2560px) {
}
