.footer {
    min-height: 30vh;
    background-color: #0008db;
    color: #fbfdf7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 32px;
}

.logoContainer {
}

.logo {
    width: 120px;
    height: 120px;
}

.instagram {
    opacity: 1;
}

.instagram:hover {
    opacity: 0.7;
    cursor: pointer;
}
.textContainer {
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
}
.text2 {
    font-family: Poppins;

    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
}
.text {
    margin-top: 24px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 42px;
    line-height: 42px;
}
@media (min-width: 400px) {
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 475px) {
}

@media (min-width: 576px) {
    .footer {
        padding-left: 32px;
        padding-right: 32px;
    }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .footer {
        display: flex;
    }
}

@media (min-width: 840px) {
    .footer {
        padding-left: 64px;
        padding-right: 64px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .footer {
        flex-direction: row;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .footer {
        padding-left: 145px;
        padding-right: 145px;
        margin-top: 120px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
@media (min-width: 1600px) {
    .footer {
        padding-left: 165px;
        padding-right: 165px;
    }
}

@media (min-width: 1900px) {
    .footer {
        padding-left: 212px;
        padding-right: 212px;
    }
}

@media (min-width: 2560px) {
}
