.top {
    background-image: url("../../../assets/svgs/Group79.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 12px;
}
.topContainer {
    height: calc(100vh - 112px);
}

.imageContainer {
    z-index: 1;
    height: 268px;
    border-radius: 15px;
    margin-top: 42px;
    background-color: #e7f2e8;
    -webkit-box-shadow: -28px 17px 0px 1px rgba(231, 242, 232, 1);
    -moz-box-shadow: -28px 17px 0px 1px rgba(231, 242, 232, 1);
    box-shadow: -28px 17px 0px 1px rgba(231, 242, 232, 1);
}

.image {
    width: 100%;
    height: 268px;
    z-index: 33;
    border-radius: 15px;
}
.topTextContainer {
    align-items: center;
    justify-content: ;
    z-index: 3;
    margin-top: 75px;
    width: 100%;
    position: relative;
}

.topText {
    font-family: Poppins;
    font-weight: bold;
    font-size: 45px;
    line-height: 50px;
    text-align: left;
    color: #0008db;
    z-index: 33;
}

.spanCont {
    background-color: rgba(14, 141, 255, 0.7);
    border-radius: 1000px;
    margin-top: -20px;
    width: 250px;
    height: 15px;
    z-index: 1;
}

.textEl {
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #515151;
    margin-top: 28px;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-11-14 13:34:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
.fadeInLeft {
    -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
        both;
    animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@media (min-width: 400px) {
    .topTextContainer {
        margin-top: 75px;
        width: 350px;
    }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 475px) {
    .topTextContainer {
        z-index: 3;
        margin-top: 75px;
        margin-left: auto;
        margin-right: auto;
    }

    .topText {
        font-family: Poppins;
        font-weight: bold;
        font-size: 45px;
        line-height: 50px;
        text-align: left;
        color: #0008db;
    }
    .imageContainer {
        width: 100%;
        height: 318px;

        margin-top: 42px;
    }

    .image {
        width: 100%;
        height: 318px;
        z-index: 33;
    }
}

@media (min-width: 576px) {
    .top {
        padding-left: 32px;
        padding-right: 32px;
    }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .topContainer {
        display: flex;
        flex-direction: row-reverse;
    }
    .imageContainer {
        width: 70%;
        height: 268px;
        margin-top: 92px;
    }

    .image {
        width: 100%;
        height: 268px;
        z-index: 33;
    }
    .topTextContainer {
        margin-left: 0;
        margin-right: auto;
        width: 325px;
    }

    .topText {
    }
    .textEl {
        margin-top: 18px;
    }
}

@media (min-width: 840px) {
    .top {
        padding-left: 64px;
        padding-right: 64px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .image {
        width: 500px;
        height: 360px;
    }
    .imageContainer {
        width: 500px;
        height: 360px;
        margin-top: 42px;
    }
    .topTextContainer {
        margin-right: auto;
        margin-top: 120px;
        width: 460px;
    }

    .topText {
        font-family: Poppins;
        font-weight: bold;
        font-size: 57px;
        line-height: 60px;
        text-align: left;
        color: #0008db;
        letter-spacing: -0.25px;
    }
    .spanCont {
        width: 305px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .top {
        padding-left: 145px;
        padding-right: 145px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .image {
        width: 758px;
        height: 485px;
    }
    .imageContainer {
        width: 758px;
        height: 485px;
    }
}
@media (min-width: 1600px) {
    .top {
        padding-left: 165px;
        padding-right: 165px;
    }
}

@media (min-width: 1900px) {
    .top {
        padding-left: 212px;
        padding-right: 212px;
    }
}

@media (min-width: 2560px) {
    .topTextContainer {
        margin-right: auto;
        margin-top: 160px;
        width: 460px;
    }
}
