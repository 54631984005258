.headerContainer {
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
}

.icon {
    opacity: 1;
}

.icon:hover {
    opacity: 0.8;
}
.logo {
    margin-left: auto;
}

.headerContainer a {
    margin-left: auto;
}

.headerContainer a:hover {
    opacity: 0.7;
    cursor: pointer;
}

.instagram {
    opacity: 1;
    align-self: auto;
}

.instagram @media (min-width: 400px) {
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 475px) {
}

@media (min-width: 576px) {
    .headerContainer {
        padding-left: 32px;
        padding-right: 32px;
    }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

@media (min-width: 840px) {
    .headerContainer {
        padding-left: 64px;
        padding-right: 64px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .headerContainer {
        padding-left: 145px;
        padding-right: 145px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
@media (min-width: 1600px) {
    .headerContainer {
        padding-left: 165px;
        padding-right: 165px;
    }
}

@media (min-width: 1900px) {
    .headerContainer {
        padding-left: 212px;
        padding-right: 212px;
    }
}

@media (min-width: 2560px) {
}
